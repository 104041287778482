import DesktopMenuItem, { type MenuItemProps } from 'components/MenuItem/DesktopMenuItem';

interface DesktopMenuProps {
  menuHeaderLinks: MenuItemProps[];
}

const DesktopMenu = ({ menuHeaderLinks }: DesktopMenuProps) => (
  <ul
    className={
      'relative left-0 z-10 hidden self-center bg-white duration-200 ease-in peer-[.menu-checkbox]:peer-checked:translate-x-0 sm:flex'
    }
  >
    {menuHeaderLinks.map(
      (menuLink, idx) =>
        menuLink.subMenu.length > 0 && (
          <li key={idx} className="self-center px-5 py-5 sm:px-2 sm:py-0 lg:px-4">
            <DesktopMenuItem {...menuLink} />
          </li>
        ),
    )}
  </ul>
);

export default DesktopMenu;
